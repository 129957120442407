import { useParams } from "react-router-dom";
import MainContent from "./MainContent";

/**
 * 
 * @returns main content with userId passed so form is propagated with user details
 */
const Manage = () => {
  const { userId } = useParams();

  return <MainContent userId={userId} />;
};

export default Manage;
