import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import FooterNav from "./FooterNav";
import BackgroundLogo from "./BackgroundLogo";
import { Container } from "@mui/material";

const queryClient = new QueryClient();

function Layout() {
  return (
    <QueryClientProvider client={queryClient}>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "background.default",
          overflow: "auto",
        }}
      >
        <NavBar />
        <FooterNav />
        <BackgroundLogo />
        <Outlet />
      </Container>
    </QueryClientProvider>
  );
}

export default Layout;
