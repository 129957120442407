import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#42a5f5',
    },
    secondary: {
      main: '#CD2026',
    },
    background: {
      default: '#101222',
      paper: '#101222',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Noto Sans, sans-serif',
      fontWeight: 900
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: 'white',
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
    },
  },
});

export default theme