import logo from "../assets/img/background-logo.svg";

const BackgroundLogo = () => {
  return (
    <div
      style={{
        display: "block",
        position: "fixed",
        right: "2vw",
        bottom: "-10vh",
        overflow: "hidden",
        zIndex: '-100',
      }}
    >
      <img src={logo} alt="local beat logo"/>
    </div>
  );
};

export default BackgroundLogo;
