import { Container, Typography } from "@mui/material";
// import Markdown from "../components/CustomMarkdown";
// import { useMdFromLocal } from "../services/queries";
// import { useEffect, useState } from "react";

/**
 *
 * @returns current privacy policy
 * to adjust either replace the file name below or overwrite current listed file
 */
const PrivacyPolicy = () => {
  // const content = useMdFromLocal("privacy-policy.md");
  const linkStyle = { color: "#3F9DE9" };

  return (
    <Container
      sx={{
        width: { xs: "80%", md: "900px" },
        mb: 15,
      }}
      key={"privacyContainer"}
    >
      {/* <Markdown children={content} /> */}
      <Typography variant="h6">LOCALBEAT LLC PRIVACY POLICY</Typography>
      <Typography variant="caption" sx={{ mb: 3, display: "block" }}>
        Last Updated: July 1, 2024
      </Typography>
      <Typography variant="body2" paragraph={true}>
        Our Privacy Policy was last updated and posted on July 1, 2024. It
        governs the privacy terms of our Website, located at
        <a style={linkStyle} href="https://www.localbeat.org">
          {" "}
          LOCALBEAT.org
        </a>
        , sub-domains, and any associated web-based and mobile applications
        (collectively, “Website”), as owned and operated by LocalBeat LLC. Any
        capitalized terms not defined in our Privacy Policy, have the meaning as
        specified in our Terms of Service. Your privacy is very important to us.
        Accordingly, we have developed this Policy in order for you to
        understand how we collect, use, communicate and disclose and make use of
        personal information. We use your Personal Information only for
        providing and improving the website. By using the website, you agree to
        the collection and use of information in accordance with this policy.
        Unless otherwise defined in this Privacy Policy, terms used in this
        Privacy Policy have the same meanings as in our{" "}
        <a style={linkStyle} href="https://www.localbeat.org/terms">
          Terms of Use
        </a>
        . The following outlines our privacy policy:
        <ul>
          <li>
            Before or at the time of collecting personal information, we will
            identify the purposes for which information is being collected.
          </li>
          <li>
            We will collect and use personal information solely with the
            objective of fulfilling those purposes specified by us and for other
            compatible purposes, unless we obtain the consent of the individual
            concerned or as required by law.
          </li>
          <li>
            We will only retain personal information as long as necessary for
            the fulfillment of those purposes.
          </li>
          <li>
            We will collect personal information by lawful and fair means and,
            where appropriate, with the knowledge or consent of the individual
            concerned. Personal data should be relevant to the purposes for
            which it is to be used, and, to the extent necessary for those
            purposes, should be accurate, complete, and up-to-date.
          </li>
          <li>
            We will protect personal information by reasonable security
            safeguards against loss or theft, as well as unauthorized access,
            disclosure, copying, use or modification.
          </li>
          <li>
            We will make readily available to customers information about our
            policies and practices relating to the management of personal
            information.
          </li>
        </ul>
      </Typography>
      <Typography variant="h6">Your Privacy</Typography>
      <Typography variant="body2" paragraph={true}>
        LocalBeat LLC follows all legal requirements to protect your privacy.
        Our Privacy Policy is a legal statement that explains how we may collect
        information from you, how we may share your information, and how you can
        limit our sharing of your information. We utilize the Personal Data you
        offer in a way that is consistent with this Personal privacy Policy. If
        you provide Personal Data for a particular reason, we could make use of
        the Personal Data in connection with the reason for which it was
        provided. For example, registration info sent when developing your
        account, might be used to suggest products to you based on past
        acquisitions. We might use your Personal Data to offer access to
        services on the Website and monitor your use of such services. LocalBeat
        LLC may also utilize your Personal Data and various other personally
        non-identifiable info gathered through the Website to assist us with
        improving the material and functionality of the Website, to much better
        comprehend our users, and to improve our services. You will see terms in
        our Privacy Policy that are capitalized. These terms have meanings as
        described in the Definitions section below.
      </Typography>
      <Typography variant="h6">Definitions</Typography>
      <Typography variant="body2" paragraph={true}>
        “Non Personal Information” is information that is not personally
        identifiable to you and that we automatically collect when you access
        our Website with a web browser. It may also include publicly available
        information that is shared between you and others. “Personally
        Identifiable Information” is non-public information that is personally
        identifiable to you and obtained in order for us to provide you our
        Website. Personally Identifiable Information may include information
        such as your name, email address, and other related information that you
        provide to us or that we obtain about you.
      </Typography>
      <Typography variant="h6">Information We Collect</Typography>
      <Typography variant="body2" paragraph={true}>
        Generally, you control the amount and type of information you provide to
        us when using our Website. As a Visitor, you can browse our website to
        find out more about our Website. You are not required to provide us with
        any Personally Identifiable Information as a Visitor.
      </Typography>

      <Typography variant="h6">Computer Information Collected</Typography>
      <Typography variant="body2" paragraph={true}>
        When you use our Website, we automatically collect certain computer
        information by the interaction of your mobile phone or web browser with
        our Website. Such information is typically considered Non Personal
        Information. We also collect the following:
        <ul>
          <li>
            Geographical Information
            <br />
            When you use the mobile application, we may use GPS technology (or
            other similar technology) to determine your current location in
            order to determine the city you are located in and display
            information with relevant data or advertisements. We will not share
            your current location with other users or partners. If you do not
            want us to use your location for the purposes set forth above, you
            should turn off the location services for the mobile application
            located in your account settings or in your mobile phone settings
            and/or within the mobile application.
          </li>
          <li>
            Automatic Information
            <br />
            We automatically receive information from your web browser or mobile
            device. This information includes the name of the website from which
            you entered our Website, if any, as well as the name of the website
            to which you’re headed when you leave our website. This information
            also includes the IP address of your computer/proxy server that you
            use to access the Internet, your Internet Website provider name, web
            browser type, type of mobile device, and computer operating system.
            We use all of this information to analyze trends among our Users to
            help improve our Website.
          </li>

          <li>
            Log Data
            <br />
            Like many Website operators, we collect information that your
            browser sends whenever you visit our Website (“Log Data”). This Log
            Data may include information such as your computer’s Internet
            Protocol (“IP”) address, browser type, browser version, the pages of
            our Website that you visit, the time and date of your visit, the
            time spent on those pages and other statistics.
          </li>
        </ul>
      </Typography>

      <Typography variant="h6">How We Use Your Information</Typography>
      <Typography variant="body2" paragraph={true}>
        We use the information we receive from you as follows:
        <ul>
          <li>
            Customizing Our Website
            <br />
            We may use the Personally Identifiable information you provide to us
            along with any computer information we receive to customize our
            Website.
          </li>
          <li>
            Sharing Information with Affiliates and Other Third Parties
            <br />
            We do not sell, rent, or otherwise provide your Personally
            Identifiable Information to third parties for marketing purposes. We
            may provide your Personally Identifiable Information to affiliates
            that provide services to us with regards to our Website (i.e.
            payment processors, Website hosting companies, etc.); such
            affiliates will only receive information necessary to provide the
            respective services and will be bound by confidentiality agreements
            limiting the use of such information.
          </li>
          <li>
            Data Aggregation
            <br />
            We retain the right to collect and use any Non Personal Information
            collected from your use of our Website and aggregate such data for
            internal analytics that improve our Website and Service as well as
            for use or resale to others. At no time is your Personally
            Identifiable Information included in such data aggregations.
          </li>
          <li>
            Legally Required Releases of Information
            <br />
            We may be legally required to disclose your Personally Identifiable
            Information, if such disclosure is (a) required by subpoena, law, or
            other legal process; (b) necessary to assist law enforcement
            officials or government enforcement agencies; (c) necessary to
            investigate violations of or otherwise enforce our Legal Terms; (d)
            necessary to protect us from legal action or claims from third
            parties including you and/or other Members; and/or (e) necessary to
            protect the legal rights, personal/real property, or personal safety
            of LocalBeat LLC, our Users, employees, and affiliates.
          </li>
        </ul>
      </Typography>

      <Typography variant="h6">Links to Other Websites</Typography>
      <Typography variant="body2" paragraph={true}>
        Our Website may contain links to other websites that are not under our
        direct control. These websites may have their own policies regarding
        privacy. We have no control of or responsibility for linked websites and
        provide these links solely for the convenience and information of our
        visitors. You access such linked Websites at your own risk. These
        websites are not subject to this Privacy Policy. You should check the
        privacy policies, if any, of those individual websites to see how the
        operators of those third-party websites will utilize your personal
        information. In addition, these websites may contain a link to Websites
        of our affiliates. The websites of our affiliates are not subject to
        this Privacy Policy, and you should check their individual privacy
        policies to see how the operators of such websites will utilize your
        personal information.
      </Typography>

      <Typography variant="h6">Security</Typography>
      <Typography variant="body2" paragraph={true}>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage, is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security. We utilize practical protection
        measures to safeguard against the loss, abuse, and modification of the
        Individual Data under our control. Personal Data is kept in a secured
        database and always sent out by means of an encrypted SSL method when
        supported by your web browser. No Web or email transmission is ever
        totally protected or mistake cost-free.
      </Typography>

      <Typography variant="h6">Privacy Policy Updates</Typography>
      <Typography variant="body2" paragraph={true}>
        We reserve the right to modify this Privacy Policy at any time. If we
        make material changes to this policy, we may notify you on our Website,
        by a blog post, by email, or by any method we determine. The method we
        chose is at our sole discretion. We will also change the “Last Updated”
        date at the beginning of this Privacy Policy. Any changes we make to our
        Privacy Policy are effective as of this Last Updated date and replace
        any prior Privacy Policies.
      </Typography>

      <Typography variant="h6">
        Questions About Our Privacy Practices or This Privacy Policy
      </Typography>
      <Typography variant="body2" paragraph={true}>
        We are committed to conducting our business in accordance with these
        principles in order to ensure that the confidentiality of personal
        information is protected and maintained. If you have any questions about
        our Privacy Practices or this Policy, please contact us.
      </Typography>

      <Typography variant="h6">Google Analytics</Typography>
      <Typography variant="body2" paragraph={true}>
        Our Site uses Google Analytics, a web analytics service provided by
        Google, Inc. (“Google”). The information generated by the Google
        Analytics cookie about your use of the Site will be transmitted to and
        stored by Google on servers in the United States. Google will use this
        information for the purpose of evaluating your use of the website,
        compiling reports on website activity for website operators and
        providing other services relating to website activity and internet
        usage. Google may use the data collected to contextualize and
        personalize the ads of its own advertising network. Google may also
        transfer this information to third parties where required to do so by
        law, or where such third parties process the information on Google’s
        behalf. Google will not associate your IP address with any other data
        held by Google. You can opt-out of the Google Analytics Advertising
        Features we use by indicating your preference using the interest-based
        opt-out link{" "}
        <a
          style={linkStyle}
          href="https://optout.aboutads.info/?lang=EN&c=2#!%2F"
        >
          here
        </a>
        . Google also provides a complete privacy policy and instructions on
        opting-out of Google Analytics{" "}
        <a style={linkStyle} href="https://tools.google.com/dlpage/gaoptout/">
          here
        </a>
        .
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
