import { Box, Chip, FormControl, FormGroup, FormLabel } from "@mui/material";
import React from "react";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { Genres } from "../types/genres";

type Props<T extends FieldValues> = {
  name: Path<T>;
  options: Genres[];
  label?: string;
};

const ChipSelect = <T extends FieldValues>({
  name,
  label,
  options,
}: Props<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <FormControl error={!!error}>
          {label && <FormLabel>{label}</FormLabel>}
          <FormGroup>
            <Box>
              {options?.map((option) => (
                <Chip
                  label={option.categoryName}
                  key={option.categoryCode}
                  clickable
                  color={
                    value.includes(option.categoryCode)
                      ? "secondary"
                      : "default"
                  }
                  onClick={() => {
                    if (value.includes(option.categoryCode)) {
                      onChange(
                        (value as string[]).filter(
                          (item) => item !== option.categoryCode
                        )
                      );
                    } else {
                      onChange([...value, option.categoryCode]);
                    }
                  }}
                  sx={{
                    m: "1em",
                    p: "1em 1.5em",
                    fontSize: ".9rem",
                  }}
                />
              ))}
            </Box>
          </FormGroup>
        </FormControl>
      )}
    />
  );
};

export default ChipSelect;
