import { FormProvider, useForm } from "react-hook-form";
import Form from "./Form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DevTool } from "@hookform/devtools";
import { defaultValues, Schema, schema } from "../types/formSchema";

type Props = {
  show: boolean;
  userId?: string;
};

/**
 *
 * @param Props
 * @returns provider of schema and methods for form child
 */
const CustomFormProvider = ({ show, userId }: Props) => {
  const methods = useForm<Schema>({
    mode: "all",
    resolver: zodResolver(schema),
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <Form show={show} userId={userId} />
      {/* <DevTool control={methods.control} /> */}
    </FormProvider>
  );
};

export default CustomFormProvider;
