import { z } from "zod";
import { patterns } from "../constants";

/**
 * zod schema for form
 * used to validate inputs
 */
export const schema = z.object({
  name: z
    .string()
    .min(1, { message: "Required" })
    .refine((text) => patterns.name.test(text), {
      message: "Please enter your full name. Be sure to remove any trailing spaces.",
    }),
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .refine((text) => patterns.email.test(text), {
      message: "Email not valid",
    }),
  zip: z
    .string()
    .min(1, { message: "Required" })
    .refine((text) => patterns.zipCode.test(text), {
      message: "Please enter a valid zip code",
    }),
  campaigns: z.array(z.string()),
  genres: z.array(z.string()),
  categories: z.array(z.string()),
});

/**
 * schema type for form
 */
export type Schema = z.infer<typeof schema>;

/**
 * defaults values for form
 */
export const defaultValues: Schema = {
  name: "",
  email: "",
  zip: "",
  campaigns: [],
  genres: [],
  categories: [],
};
