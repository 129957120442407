export const genresShown: string[] = [
  "BLUEGRASS",
  "BLUES",
  "ROCK",
  "TRIBUTE",
  "ALTERNATIVE",
  "FOLK",
  "COUNTRY",
  "POP",
  "R_AND_B",
  "ELECTRONIC_EDM",
  "JAZZ_MUSIC",
  "LATIN",
  "PUNK",
  "DJ",
  "HIP_HOP_MUSIC",
  "REGGAE",
  "CLASSICAL",
  "METAL",
  "WORLD",
  "COVERS",
  "VOCAL",
  "ORIGINALS",
  "GUITAR",
  "PIANO",
];
