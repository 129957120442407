import { Typography } from "@mui/material";
import theme from "../styles/theme";

type Props = {
  title: string;
  allCaps?: boolean;
};

/**
 *
 * @param title - text to be in title
 * @param allCaps - optional. if passed, puts text in uppercase
 * @returns <h1 /> style component
 */
const H1 = ({ title, allCaps = true }: Props) => {
  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: "3rem",
        textDecoration: "underline",
        textDecorationColor: theme.palette.secondary.main,
        textAlign: "center",
      }}
    >
      {allCaps ? getCaps(title) : title}
    </Typography>
  );
};

const getCaps = (title: string) => {
  return title.toUpperCase();
};

export default H1;
