import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const categoryUrl: string = process.env.REACT_APP_CATEGORY_API_URL || "";
const campaignUrl: string = process.env.REACT_APP_CAMPAIGN_API_URL || "";
const userUrl: string = process.env.REACT_APP_GET_USER_URL || "";

export const useCategories = () => {
  return useQuery({
    queryKey: ["genres"],
    queryFn: () =>
      axios
        .get(categoryUrl)
        .then((res) => res.data)
        .catch((error) => console.error(error)),
  });
};

export const useCampaigns = () => {
  return useQuery({
    queryKey: ["campaigns"],
    queryFn: () =>
      axios
        .get(campaignUrl)
        .then((res) => res.data)
        .catch((e) => console.error(e)),
  });
};

export const useMdFromLocal = (fileName: string) => {
  const { data } = useQuery({
    queryKey: ["markdown"],
    queryFn: () =>
      axios
        .get(`/legal/${fileName}`)
        .then((res) => res.data)
        .catch((e) => console.error(e)),
  });
  return data;
};

export const useUser = (id?: String) => {
  return useQuery({
    queryKey: ["user", { id }],
    queryFn: () =>
      axios
        .get(`${userUrl}${id}`)
        .then((res) => res.data)
        .catch((e) => console.error(e)),
    // ensures only runs if valid id provided
    enabled: !!id,
  });
};
