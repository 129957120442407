import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Schema } from "../types/formSchema";

const newUserMutationUrl = process.env.REACT_APP_POST_NEW_USER_API_URL || "";

/**
 * mutation to post new user data to api
 */
export const useCreateUser = () => {
  return useMutation({
    mutationFn: async (data: Schema) => {
      // normalize data
      let newUser = normalizeData(data);
      await axios.post(newUserMutationUrl, newUser);
    },
    // could be useful, not implemented (invalidate query)
    onSuccess: async () => {},
    onError: async (error) => {
      console.error(error);
    },
  });
};

/**
 *
 * @param data
 * @returns newUser - normalized object for newUser
 */
export const normalizeData = (data: Schema) => {
  let name = data.name.split(" ");
  let categories = data.categories
    .map((i) => ({
      categoryCode: i,
    }))
    .concat(
      data.genres.map((i) => ({
        categoryCode: i,
      }))
    );
  const newUser = {
    firstName: name[0],
    lastName: name.length > 1 ? name[name.length - 1] : "",
    email: data.email,
    zip: data.zip,
    categories,
    userCampaigns: data.campaigns,
  };
  return newUser;
};
