import { defaultValues } from "../types/formSchema";
import { genresShown } from "./genresShown";

/**
 * filters just genres of music for display in form
 * @param genre
 * @returns null
 */
export const genreFilter = (genre: {
  categoryCode: string;
  parent: { categoryCode: string } | null;
}) =>
  genre.parent !== null &&
  genre.parent.categoryCode === "MUSIC" &&
  genresShown.includes(genre.categoryCode);

/**
 * sorts a given array alphabetically based on given key
 * @param array
 * @param sortKey
 * @returns sorted array of objects
 */
export const alphabeticalSort = (array: any, sortKey: string) =>
  array.sort((a: { [x: string]: string }, b: { [x: string]: string }) => {
    const valueA = a[sortKey].toLowerCase();
    const valueB = b[sortKey].toLowerCase();
    return valueA.localeCompare(valueB);
  });

/**
 * filters just the categories currently used for email campaigns
 * @param genre
 * @returns null
 */
export const categoryFilter = (genre: {
  categoryCode: string;
  parent: { categoryCode: string } | null;
}) =>
  genre.parent === null &&
  (genre.categoryCode === "COMEDY" ||
    genre.categoryCode === "MUSIC" ||
    genre.categoryCode === "THEATER");

/**
 * maps existing category object to just a string
 * @param i category object
 * @returns string of category code for use in form
 */
export const categoryMapper = (i: { category: { categoryCode: string } }) =>
  i.category.categoryCode;

/**
 * takes in data fetched for existing user and formats for use with form
 * returns default values if they don't exist within object
 * @param data
 * @returns formatted data object for form
 */
export const formatFetchedUserData = (data: any) => {
  return {
    name:
      data.data.firstName.concat(` ${data.data.lastName}`) ||
      defaultValues.name,
    email: data.data.email || defaultValues.email,
    zip: data.data.zip || defaultValues.zip,
    campaigns: data.data.userCampaigns
      ? data.data.userCampaigns.map(
          (item: { campaignId: string }) => item.campaignId
        )
      : defaultValues.campaigns,
    genres: data.data.categories
      ? data.data.categories
          .filter(
            (i: { category: any; parent: any }) => i.category.parent != null
          )
          .map(categoryMapper)
      : defaultValues.genres,
    categories: data.data.categories
      ? data.data.categories
          .filter(
            (i: { category: any; parent: any }) => i.category.parent == null
          )
          .map(categoryMapper)
      : defaultValues.categories,
  };
};
