import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from "@mui/material";
import logo from "../assets/img/LocalBeatLogo.svg";
import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

/**
 *
 * @returns Nav menu in footer - has links to legal docs and home page
 */
const FooterNav = () => {
  let [value, setValue] = useState(useLocation().pathname);
  const styles = {
    background: `linear-gradient(0deg, rgba(16,18,34,1) 0%, 
      rgba(16,18,34,0.85) 75%, 
      rgba(16,18,34,0.65) 100%)`,
    borderRadius: 3,
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 5,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 10,
      }}
    >
      <Paper elevation={3} sx={styles}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={styles}
        >
          <BottomNavigationAction
            label="Terms"
            component={RouterLink}
            to="/terms"
            value="/terms"
          />
          <BottomNavigationAction
            label=""
            icon={
              <img
                src={logo}
                className="logo"
                alt="Local Beat Logo"
                style={{ scale: "50%" }}
              />
            }
            component={RouterLink}
            to="/"
            value="/"
          />
          <BottomNavigationAction
            label="Privacy"
            component={RouterLink}
            to="/privacy"
            value="/privacy"
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default FooterNav;
