import { Stack, Typography } from "@mui/material";
import H1 from "../components/H1";

/**
 * 
 * @returns 404 page
 */
const NoPage = () => {
  return (
    <Stack spacing={3} alignItems="center" mt={6}>
      <H1 title="404" allCaps />
      <Typography variant="h4">
        Uh oh! There are no events on this page.
      </Typography>
      <Typography variant="h5">
        Click our logo below to go home. 
      </Typography>
    </Stack>
  );
};

export default NoPage;
