import { AppBar, Box, Toolbar } from "@mui/material";
import logo from "../assets/img/LocalBeatLogo.svg";
import localBeatName from "../assets/img/LocalBeatName.svg";
import { Link } from "react-router-dom";

/**
 * currently only holds home button
 * @returns top nav bar component
 */
const NavBar = () => {
  return (
    <AppBar
      position="sticky"
      sx={{
        background: "transparent",
        boxShadow: "none",
        zIndex: -10,
      }}
      enableColorOnDark
    >
      <Toolbar disableGutters>
        <Link to="/">
          <Box
            sx={{ display: { xs: "none", md: "flex" }, m: 1, p: 1, mb: 0 }}
            height={"50px"}
            width={"150px"}
          >
            <img
              src={logo}
              className="logo"
              alt="Local Beat Logo"
              style={{ marginRight: ".5em" }}
            />
            <img src={localBeatName} className="logo" alt="Local Beat Logo" />
          </Box>
          <Box
            mt={1.25}
            ml={1.25}
            sx={{
              display: { xs: "flex", md: "none" },
              borderRadius: 2,
            }}
          >
            <img
              src={logo}
              className="logo"
              alt="Local Beat Logo"
              width="30px"
            />
          </Box>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
